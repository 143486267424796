module.exports = {
    common: {
        toastFail: "Error",
        toastSuccess: 'Successful',
        privacyPolicy: "개인정보 처리방침",
        conditions: "이용약관",
        sessionExpired: "휴대폰 인증 정보가 만료되었습니다. \n휴대폰 인증을 다시 시도해주세요.",
        marketing: '마케팅 및 홍보 목적의 개인정보 수집 ',
    },
    enterNumber: {
        secondTitle: "안녕하세요 고객님",
        tips: "인증번호를 받으시려면 귀하의 휴대폰 번호를 입력해주세요. 귀하가 제공한 개인정보는 당사 {0}에 따라 처리됩니다.",
        phonePlaceHolder: "고객님의 휴대폰 번호",
        phoneRuleMessage: "올바른 휴대폰 번호를 입력해주시기 바랍니다",
        getOTP: "인증 번호 요청",
        toastSuccess: "인증번호가 발송되었습니다",
        toastFail: "인증번호 발송이 실패되었습니다",
        readPrivacyPolicy: "본인은 {0}의 {1}을 모두 읽었으며 이에 동의합니다  ",
        privacyPolicy: "개인정보처리방침",
    },
    enterOTP: {
        secondTitle: "인증번호를 입력해주세요",
        tips: "고객님께서 등록하신 휴대폰 번호로 전송된 인증 번호 6자리를 입력해주세요",
        resendOTP: "인증 번호 재전송",
        resendOTPCountdown: "초 이후 재전송 가능합니다",
        invalidOTP: "잘못된 인증 번호",
        OTPRuleMessage1: "입력하신 인증번호가 잘못되었습니다.",
        OTPRuleMessage2: "인증번호를 재입력하신 후 다시 시도해주세요.",
        verify: "확인",
        ok: "확인",
        expiredMessage: "인증번호가 만료되었습니다. 재전송 해주세요.",
        toastSuccess: "모바일 인증 성공",
        toastFail: "모바일 인증 실패",
    },
    registration: {
        counter: '등록매장',
        formValueFirstName: "성명",
        formValueDay: "일",
        formValueMonth: "월",
        formValueYear: "년",
        formValueMobileNumberth: "핸드폰",
        formValueMobileEmailth: "이메일 주소",
        formValueAddress1: '주소1',
        formValueAddress2: '주소2',
        formValueAddress3: '상세 주소',
        formValueZipcode: '우편번호',
        bothPerAndProYN: "모두 동의(전체 동의는 필수 및 선택 정보에 대한 동의도 포함되어 있으며, 개별적으로 동의를 선택하실 수 있습니다)",
        formValuePInfoAgree: '[필수] {0} 에 동의하며, 본인은 만 14세 이상입니다.',
        formValueMarketAgree: '[선택] {0}에 동의합니다. ',
        followAgreeYN: "[선택] 마케팅 및 홍보성 정보 수신에 동의합니다.",
        followAgreeYN1: "마케팅 수신 및 홍보 목적의 개인정보 수집 및 이용에 미동의 시, 마케팅 목적의 소식 및 특별 혜택 정보를 받아 보실 수 없습니다.",
        followAgreeYN2: "이용자는 회원 가입을 함으로써 에스티로더컴퍼니즈의 {0}을 이해하였음을 확인합니다.",
        followAgreeYN3: "필수 동의사항에 동의를 거부할 수 있지만 거부시 서비스 이용이 제한될 수 있음을 알려드립니다.",
        formValueSMS: '문자/동영상 수신동의',
        formValueDM: 'DM (우편물) 수신동의',
        formValueEmail: '이메일/뉴스레터 수신동의',
        formValueCall: '전화 연락 수신동의',
        submit: "제출",
        confirm: '확인 완료',
        rulerFirstname: '이름을 입력해 주세요',
        rulerEmail: '유효한 이메일 주소를 입력해 주세요',
        rulerBirthmonth: '생일 월을 선택해 주세요.',
        alreadyRP: '이미 가입 된 번호 입니다',
        privacyPolicy: "Privacy Policy",
        mobileAlert: "올바른 휴대폰 번호를 입력해주시기 바랍니다",
        formValueChannelAgree: "{0}의 새로운 서비스, 신상품, 이벤트 정보 등 마케팅 목적의 최신 정보를 미리 받아보고 싶으시다면, 수신 채널을 선택해 주세요",
        formValueFollowAgree: '아래 나열된 모든 것',
        rulerFirstname1: '영문 혹은 국문으로 입력해주세요',
        rulerZipCode: '우편 번호를 입력해 주세요',
        rulerPersonalInfo1: '이용 약관에 동의해 주세요.',
        rulerPersonalInfo2: '개인 정보 활용 동의는 필수 입니다',
        rulerMarketingAgreement: '마케팅 정보 수신에 동의하려면 개인정보 수집 동의가 필요합니다.',
        male: '남',
        female: '여',
        gender: '성별',
        birthDay: '생년월일',
        select: '선택',
    },
    barcode: {
        wel: '{0} 회원 가입에 감사 드립니다',
        please: '바코드를 매장'
    },
    registSuccess: {
        wel: '{0} 회원 가입에 감사 드립니다',
    }
}
