module.exports = {
    common: {
        enter: 'Please Enter',
        toastFail: "Error",
        toastSuccess: 'Successful',
        privacyPolicy: "Privacy Policy",
        conditions: "Terms of Use",
        sessionExpired: "Mobile authentication has expired. \nPlease try mobile authentication again.",
        welcome: "Welcome",
        submit: 'Submit',
        confirm: 'Confirm',
        check: 'Check',
        marketing: 'collection of personal information for marketing and promotional purposes',
    },
    enterEmail: {
        tips: "Please enter your email address for verification",
        toastSuccess: 'Successful',
        toastFail: 'Fail',
    },
    enterNumber: {
        secondTitle: "Welcome",
        tips: "Please enter your cell phone number to receive a one-time authentication number. Your personal information will be processed in accordance with our {0}.",
        phonePlaceHolder: "Your cell phone number",
        phoneRuleMessage: "Please enter a valid cell phone number",
        getOTP: "Request for Authentication Number",
        toastSuccess: "Successfully sent",
        toastFail: "Failed to send",
        readPrivacyPolicy: "I have read and agree to {0}'s {1}.",
        privacyPolicy: "Privacy Policy",
    },
    enterOTP: {
        secondTitle: "Enter your Authentication Number",
        tips: "Please enter the six digits one-time authentication number sent via text to your registered cell phone number",
        jpnTips: "Please enter the six digits one-time authentication number sent via text to your registered email",
        resendOTP: "Resend Authentication Number",
        resendOTPCountdown: "Resent to be enabled after",
        invalidOTP: "Invalid Authentication Number",
        OTPRuleMessage1: "The authentication number you entered was invalid",
        OTPRuleMessage2: "Please re-enter and try again",
        verify: "Check",
        ok: "Ok",
        expiredMessage: "Authentication number has been expired. Please resend again.",
        toastSuccess: "Successful",
        toastFail: "   Failed   ",
        limitMsg1: 'One email can request only two OTPs in one hour.',
        limitMsg2: 'Please try again later.',
        limit: 'OTP limit exceeded',
    },
    registration: {
        counter: 'Registration Store',
        formValueFirstName: "Name",
        formValueDay: "Day",
        formValueMonth: "Month",
        formValueYear: "Year",
        formValueMobileNumberth: "Cell Phone Number",
        formValueMobileEmailth: "Email Address",
        formValueAddress1: 'Address1',
        formValueAddress2: 'Address2',
        formValueAddress3: 'Address3',
        formValueZipcode: 'Zipcode',
        bothPerAndProYN: "Agree to All (Agreeing to all includes consent to both mandatory and optional information, and you can select consent individually)​",
        formValuePInfoAgree: '[Mandatory] I agree to the {0}, and I confirm that I am over 14 years old.',
        formValueMarketAgree: '[Optional] I agree to the {0}.',
        followAgreeYN: "[Optional] I agree to receive marketing and promotional information.",
        followAgreeYN1: "If you do not agree to the collection and use of personal information for marketing and promotional purposes, you will not receive news and special offers related to marketing.",
        followAgreeYN2: "By registering as a member, you confirm that you understand the {0} of the {1}/Estée Lauder Companies.",
        followAgreeYN3: "You may refuse to provide consent that is mandatory, but if you refuse, you may be restricted from using the service.",
        formValueSMS: 'SMS',
        formValueDM: 'DM',
        formValueEmail: 'Email',
        formValueCall: 'Call',
        submit: "Submit",
        confirm: 'Confirm',
        rulerFirstname: 'Please enter your name',
        rulerFirstname1: 'Please enter your name in alphabet or Korean only',
        rulerEmail: 'Please enter a valid email address',
        rulerBirthmonth: 'Please select your birth month',
        alreadyRP: 'This cell phone number has been registered. Please contact our Store Personnel for assistance',
        privacyPolicy: "Privacy Policy",
        mobileAlert: "Please enter a valid cell phone number",
        formValueChannelAgree: "If you would like to receive the latest information for marketing purposes, such as {0}'s new products and events, please select the receiving channel.",
        formValueFollowAgree: 'All of the follow',
        rulerZipCode: 'Please enter a valid zipcode',
        rulerPersonalInfo1: 'Please agree to terms and conditions.',
        rulerPersonalInfo2: 'The personal information usage agreement is mandatory',
        rulerMarketingAgreement: 'In order to consent to receiving marketing info, your consent to collect PI for the purpose of marketing is also required.',
        male: 'Male',
        female: 'Female',
        gender: 'Gender',
        birthDay: 'Birthday',
        select: 'Select',
    },
    barcode: {
        wel: 'Thank you for joining {0}.Please present this barcode at the counter.',
        username: '{0}',
        bindConfirmMessage: 'LINE Binding has been completed.'
    },
    registSuccess: {
        wel: 'Thank you for joining {0}',
    },
    jpnRegistration: {
        counter: 'Store Name',
        localFirstName: 'First Name',
        localLastName: 'Last Name',
        localFirstName2: 'First Name 2 (katakana)',
        localLastName2: 'Last Name 2 (katakana)',
        phone: 'Mobile Phone',
        email: 'Email Address',
        day: "Day",
        month: "Month",
        year: "Year",
        birth: 'BirthDay',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        select: 'Select',
        address1: 'Address1',
        address2: 'Address2',
        zipCode: 'Postal Code',
        prefectures: 'Prefecture',
        municipalities: 'City',
        pInfoAgree: 'I consent to Estee Lauder Companies {0}. I consent that ELC may transfer your personal information to Department Stores, Retailers or Operators of Other Commercial Facilities ("Stores, etc.”) based on the ELC`s Privacy Policy if you have provided your personal information to us at spaces of such Stores, etc. ',
        promotionAgree1: 'I would like to receive communications by emails, SNS, SMS, DM etc.',
        promotionAgree2: '(We will deliver the latest product information and advantageous campaign information. If you do not need it, please uncheck it.)',
        errMessageFirstName: 'Please enter your first name',
        errMessageLastName: 'Please enter your last name',
        errMessageFirstName2: 'Please enter your first name 2 (katakana)',
        errMessageLastName2: 'Please enter your last name 2 (katakana)',
        errMessageEmail: 'Please enter a valid email address',
        errMessagePhone: 'Please enter your mobile phone number',
        errMessageBirthDay: 'Please enter your birth day',
        errMessageBirthMonth: 'Please enter your birth month',
        errMessagePersonalInfo: 'Please agree to the privacy policy',
        errMessageZipCode: 'Please enter a valid postal code',
        alreadyRegistered: 'This email address has been registered',
        confirmLineBinding: 'Would you like to proceed with LINE binding to receive exclusive updates?',
        confirmLineBindingYes: 'Yes',
        confirmLineBindingNo: 'No'
    }
}
